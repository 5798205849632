/**
 * Converts a date string from "YYYY-MM-DD" format to "DD-MM-YYYY" format.
 *
 * @param {string} dateString - The date string to convert.
 * @returns {string} Returns the converted date string.
*/
function convertDateFormat(dateString) {
    const parts = dateString.split('-');
    const date = ''.concat(parts[2], '-', parts[1], '-', parts[0]); // Year, month, day (note: month is 0-indexed)
    return date;
}

/**
 * Parses a query string into an object.
 *
 * @param {string} queryStringParam - The query string to parse.
 * @returns {Object} Returns an object containing key-value pairs parsed from the query string.
*/
function parseQueryString(queryStringParam) {
    const queryString = queryStringParam.substring(1);
    const pairs = queryString.split('&');
    const result = {};
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        const key = decodeURIComponent(pair[0]); // Decode the key
        const value = decodeURIComponent(pair[1] || ''); // Decode the value (use empty string if undefined)
        if (Object.hasOwnProperty.call(result, key)) {
            if (!Array.isArray(result[key])) {
                result[key] = [result[key]];
            }
            result[key].push(value);
        } else {
            result[key] = value;
        }
    }
    return result;
}

/**
 * Checks if an object has specific properties.
 *
 * @param {Object} obj - The object to check.
 * @param {string[]} properties - An array of property names to check.
 * @returns {boolean} Returns true if all properties are found in the object, otherwise false.
*/
function checkProperties(obj, properties) {
    for (const property of properties) {
        if (!Object.prototype.hasOwnProperty.call(obj, property)) {
            return false;
        }
    }
    return true;
}


/**
 * Retrieves an array of unique texts from elements with the specified data-amplitude attribute value.
 *
 * @param {string} attributeValue - The value of the data-amplitude attribute to search for.
 * @returns {Array} - An array containing unique text values from elements with the specified data-amplitude attribute.
 */
function getArrayParams(attributeValue) {
    var uniqueTexts = {};
    var newArray = [];

    $('[data-amplitude=' + attributeValue + ']').each(function () {
        var text = $(this).text().trim();
        if (!uniqueTexts[text]) {
            uniqueTexts[text] = true;
            newArray.push(text);
        }
    });

    return newArray;
}

function getArrayParamsFromInputs(attributeValue) {
    var uniqueTexts = {};
    var newArray = [];

    $('[data-amplitude=' + attributeValue + ']').each(function () {
        var text = $(this).val().trim();
        if (!uniqueTexts[text]) {
            uniqueTexts[text] = true;
            newArray.push(text);
        }
    });

    return newArray;
}

function convertDateFormat(dateString) {
    const parts = dateString.split('/');
    const date = ''.concat(parts[2], '-', parts[1], '-', parts[0]); // Year, month, day (note: month is 0-indexed)
    return date;
}

function getOrigin(isWebView, isMobile) {
    if (isWebView) {
        return 'WEBVIEW';
    }
    if (isMobile) {
        return 'MOBILE';
    }

    return 'DESKTOP';
}

module.exports = {
    convertDateFormat: convertDateFormat,
    parseQueryString: parseQueryString,
    checkProperties: checkProperties,
    getArrayParams: getArrayParams,
    getArrayParamsFromInputs: getArrayParamsFromInputs,
    convertDateFormat: convertDateFormat,
    getOrigin: getOrigin
};
